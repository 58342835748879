import React from "react"
import createTemplate from "../../create-template"

import { useComponents } from "../../components"

function Archer({ styles, ...props }) {
  const Elements = useComponents()
  const year = new Date().getFullYear()

  return (
    <Elements.LandingPage
      styles={styles}
      {...props}
      style={styles.forElement("page", { backgroundColor: "#ffffff" })}
    >
      <div
        className="formkit-page-header"
        style={styles.forElement("page_header")}
      >
        <div
          className="formkit-background"
          style={{
            backgroundColor: styles.forElement("background").backgroundColor
          }}
        >
          <div
            className="formkit-background-image"
            style={styles.forElement("background")}
          />
        </div>
        <div className="formkit-container">
          <Elements.Heading
            className="formkit-preheader"
            name="preheader"
            defaults={{ content: "Company Name" }}
          />
          <div
            className="formkit-card"
            style={styles.forElement("form", {
              backgroundColor: "#ffffff"
            })}
          >
            <Elements.Heading
              className="formkit-header"
              name="header"
              defaults={{ content: "Enter a catchy page headline" }}
            />
            <Elements.Region name="content" className="formkit-content">
              <Elements.Content
                defaults={{
                  content: "Provide some more detail in this subtitle"
                }}
              />
            </Elements.Region>
            <Elements.Form>
              <Elements.Errors />
              <Elements.CustomFields>
                <Elements.AddFieldButton />
                <Elements.Button
                  name="submit"
                  group="button"
                  defaults={{ content: "Subscribe" }}
                />
              </Elements.CustomFields>
            </Elements.Form>
          </div>
          <div className="formkit-footer">
            <Elements.Content
              name="disclaimer"
              defaults={{
                content: "We respect your privacy. Unsubscribe at any time."
              }}
            />
            <Elements.Content
              name="copyright"
              defaults={{
                content: `© ${year}`
              }}
            />
            <Elements.BuiltWith background="page_header" />
          </div>
        </div>
      </div>
    </Elements.LandingPage>
  )
}
Archer.style = "landing_page"
Archer.categories = ["Newsletter"]
Archer.thumbnail = ""
Archer.preview = "https://pages.convertkit.com/f8a5a1c587/0bf836c011"
Archer.fields = [
  {
    name: "email_address",
    label: "Your email address"
  }
]

export default createTemplate(Archer, { name: "Archer" })
